/**
 * Checks if the symbol is not a letter
 * @param {String} x Symbol
 * @returns {Boolean}
 */
module.exports.isSymbol = x => !x.match(/^[a-zA-Z0-9]$/);

module.exports.nameReducer = (acc, x) => isSymbol(x) ? acc + '-' : acc + x;

module.exports.extractYearAndHours = (desc, yearWord, hoursWord) => {
  let year = ''; 
  let hours = '';
  const slices = desc.split('<b>');
  slices.forEach(slice => {
    if (slice.indexOf(yearWord) !== -1) {
      year = slice.split('</b>')[1].replace('<br>', '').trim();
    } else if (slice.indexOf(hoursWord) !== -1) {
      hours = slice.split('</b>')[1].replace('<br>', '').trim();
    }
  });

  return [year, hours];
}