var Siema = require('siema');

Siema.prototype.addPagination = function() {
  const dots = this.selector.parentNode.appendChild(document.createElement('ul'));
  dots.classList.add('slider-dots');
  for (let i = 0; i < this.innerElements.length; i++) {
    const dot = document.createElement('li');
    dot.addEventListener('click', () => this.goTo(i));
    if (i === 0) {
      dot.classList.add('active');
    }
    dots.appendChild(dot);
  }
}

Siema.prototype.initCarouselControls = function() {
  for (let i = 0; i < 2; i++) {
    const el = this.selector.parentNode.appendChild(document.createElement('button'));
    switch(i) {
      case 0: 
        el.classList.add('slider-prev');
        el.addEventListener('click', () => this.prev());
        break;
      case 1:
        el.classList.add('slider-next');
        el.addEventListener('click', () => this.next());
        break;
    }
  }
}

function changeHandler() {
  const dots = this.selector.parentNode.querySelectorAll('.slider-dots li');
  dots.forEach(dot => dot.classList.remove('active'));
  dots[this.currentSlide].classList.add('active');
}

/**
 * @type Helper
 * Creates new instance of carousel slider onto given ID
 * @param {String} elemId ID of the carousel parent wrapper
 * @returns {Carousel}
 */
function initCarousel(elemId) {
  const newSiema = new Siema({
    selector: elemId,
    duration: 400,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    multipleDrag: true,
    threshold: 20,
    loop: true,
    rtl: false,
    onChange: changeHandler,
  });

  newSiema.addPagination();
  newSiema.initCarouselControls();

  return newSiema;
}


(() => {
  if (typeof module !== 'undefined' && module.exports) {
    module.exports = initCarousel;
  } else if (typeof define === 'function' && define.AMD) {
    define('initCarousel', initCarousel);
  } else {
    window.initCarousel = initCarousel;
  }
})()
